import React, { useEffect } from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

import { useDashboard } from 'dashboard';

import { formatNumber } from 'utils';

import { useWeb3 } from 'web3';

const NextClaim = () => {
  const { claimEpochs } = useDashboard();
  const { bsc } = useWeb3();

  const index = useState(0);
  const amount = useState(false);

  const onSwitchClick = (event, i) => {
    event.preventDefault();
    index.set(i);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const epochs = claimEpochs.get();
      const claimAmount = epochs[index.get()] && epochs[index.get()].amount;
      if (!isNaN(claimAmount)) amount.set({ claimable: claimAmount });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Root>
      <h4>
        <div>
          <span>Next claimable Reward </span>
          {claimEpochs.get().length > 1 ? (
            <span className="truncated">
              {(claimEpochs.get()[index.get()] || {}).address}
            </span>
          ) : (
            ''
          )}
        </div>
      </h4>
      <div className="bnb">
        <strong>{formatNumber(amount.get().claimable || 0, 3)} BNB</strong>
        <small>
          ${formatNumber(amount.get().claimable * bsc.bnbUsdPrice, 2)}
        </small>
      </div>
      {claimEpochs.get().length > 1 ? (
        <div className="switch">
          {claimEpochs.get().map((claimEpoch, i) => (
            <span
              key={i}
              className={i === index.get() ? 'selected' : ''}
              onClick={(e) => onSwitchClick(e, i)}
              data-noflip
            >
              <span></span>
            </span>
          ))}
        </div>
      ) : (
        ''
      )}
    </Root>
  );
};

export default NextClaim;
