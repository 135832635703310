import React, { useEffect, useRef, useState } from 'react';
import { Root } from './style';

import Layout from 'components/Layout/Dashboard';
import FlipBox from 'components/Common/FlipBox';
import SiteData from 'components/Common/SiteData';
import Stats from 'components/Index/Stats';

import Countdown from './Countdown';
import PriceCharts from './PriceCharts';
import HodlMarkets from './HodlMarkets';
import TodaysMarket from './TodaysMarket';
import TransactionTaxes from './TransactionTaxes';
import NextClaim from './NextClaim';

import moment from 'moment';

import {
  YEAR_SECONDS,
  currentRank,
  formatHODL,
  formatNumber,
  inWindow,
  nextRank,
  prevRank,
} from 'utils';

import { useDashboard } from 'dashboard';
import { useWeb3, INJECTED } from 'web3';

import vars from 'variables';

const Dashboard = ({ location }) => {
  const [equity, setEquity] = useState(0);
  const [position, setPosition] = useState(0);
  // const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState('pancakeswap');

  const { addWalletAddress } = useDashboard();
  const { bsc, connect } = useWeb3();

  const { addresses, balance, claimableBNB, rewarded, reinvestedHODL } =
    useDashboard();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const address = searchParams.get('a');

    if (address) {
      connect(INJECTED).then(() => {
        addWalletAddress(address);
      });
      const style = document.createElement('style');
      style.innerHTML = `
        .compact .scalable {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);

  useEffect(() => {
    const calculateEquity = () => {
      return ((balance * 100) / bsc.rewardPoolShare)
        .toFixed(4)
        .replace(/(?<=\.\d*[1-9])0+$|\.0*$/, '');
    };
    const fetchPosition = async () => {
      const res = await fetch(`/api/tokeninfo/position?balance=${balance}`);
      const data = await res.json();
      setPosition(data.count);
    };
    setEquity(calculateEquity());
    fetchPosition();
  }, [addresses, balance]);

  return (
    <Layout location={location}>
      {inWindow() ? (
        <>
          <Root>
            <div data-row-count="4">
              <FlipBox>
                <div>
                  <h4 className="hodl">$HODL Balance</h4>
                  <strong>{formatNumber(balance, 0)}</strong>
                  <small>${formatNumber(balance * bsc.hodlUsdPrice, 2)}</small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewHodlBalance"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4 className="bnb">Total BNB Earned</h4>
                  <strong>{formatNumber(rewarded, 3)} BNB</strong>
                  <small>${formatNumber(rewarded * bsc.bnbUsdPrice, 2)}</small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewBnbRewarded"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4 className="hodl">Total reinvested $HODL</h4>
                  <strong>{formatNumber(reinvestedHODL, 0)}</strong>
                  <small>
                    ${formatNumber(reinvestedHODL * (bsc.hodlUsdPrice || 0), 2)}
                  </small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewHodlFromReinvestment"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4>Current $HODL APR</h4>
                  <strong>
                    {formatNumber(
                      (((YEAR_SECONDS / bsc.rewardClaimPeriod) *
                        claimableBNB *
                        bsc.bnbUsdPrice) /
                        ((balance * bsc.hodlUsdPrice) / 0.95 || 1)) *
                        100,
                      3
                    )}
                    %
                  </strong>
                  <small>From Rewards</small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewCurrentHodlApy"
                  />
                </div>
              </FlipBox>
            </div>
            <div data-row-count="4">
              <FlipBox>
                <div>
                  <Countdown />
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewNextCollection"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <NextClaim />
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewNextCollection"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4>Your % of Reward Pool</h4>
                  <strong>{Number(equity || 0).toFixed(4)}%</strong>
                  <small>
                    {formatNumber(position)} of {formatNumber(bsc.hodlers || 0)}{' '}
                    hodlers
                  </small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewEquityInHodl"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div className="rank">
                  <img src={currentRank(balance).image} alt="balance" />
                  <strong>{currentRank(balance).name}</strong>
                  <small>LVL. {currentRank(balance).level}</small>
                  {currentRank(balance).level === 12 ? (
                    <>
                      <div className="progress">
                        <div
                          style={{
                            width: '100%',
                          }}
                        ></div>
                      </div>
                      <small className="remaining">
                        <div>Congrats. You’re king of the ocean!</div>
                      </small>
                    </>
                  ) : (
                    <>
                      <div className="progress">
                        <div
                          style={{
                            width: `${
                              ((balance -
                                prevRank(balance).thresholdAmount *
                                  prevRank(balance).thresholdFactor) /
                                (currentRank(balance).thresholdAmount *
                                  currentRank(balance).thresholdFactor -
                                  prevRank(balance).thresholdAmount *
                                    prevRank(balance).thresholdFactor)) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <small className="remaining">
                        <div>
                          {formatHODL(
                            currentRank(balance).thresholdAmount *
                              currentRank(balance).thresholdFactor -
                              balance
                          )}{' '}
                          $HODL needed
                        </div>
                        <div>LVL. {nextRank(balance).level}</div>
                      </small>
                    </>
                  )}
                </div>
                <div>
                  <SiteData className="info" path="dashboard.overviewLevel" />
                </div>
              </FlipBox>
            </div>
            <Stats />
            <div className="numbers">
              <PriceCharts selected={selected} setSelected={setSelected} />
              <div className="figures">
                <div className="numbers">
                  <HodlMarkets />
                  <TodaysMarket />
                </div>
                <TransactionTaxes
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </div>
          </Root>
        </>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default Dashboard;
