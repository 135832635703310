import React, { useEffect, useState } from 'react';
import { Root } from './style';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Helpers,
} from 'chart.js';

import moment from 'moment';

import { isEqual } from 'lodash';

import { Line } from 'react-chartjs-2';

import ExchangePicker from '../ExchangePicker';

import { formatNumber, useSiteData } from 'utils';

import vars from 'variables';

const PriceCharts = ({ selected, setSelected }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
  );

  const exchanges = useSiteData().resources.exchanges.map(
    ({ identifier }) => identifier
  );

  const usePCSData = useSiteData().resources.exchanges.reduce(
    (acc, { identifier, usePCSData }) => {
      acc[identifier] = usePCSData;
      return acc;
    },
    {}
  );

  const [data, setData] = useState({});
  const [price, setPrice] = useState(['0', '0']);
  const [change, setChange] = useState(0);
  const [resolution, setResolution] = useState('0');
  const [tooltip, setTooltip] = useState();

  useEffect(() => {
    const identifier = usePCSData[selected] ? 'pancakeswap' : selected;
    const fetchData = async () => {
      const data = await fetch(
        `/api/tokeninfo/chartprices?timewindow=${resolution}`,
        {
          method: 'GET',
        }
      );
      const priceData = (await data.json())[0];
      const oldPrice = priceData[0].value;
      const currentPrice = priceData[priceData.length - 1].value.toString();
      setPrice(currentPrice.split('.'));
      setChange(((oldPrice - currentPrice) / oldPrice) * -100);
      setData({
        labels: priceData.map(({ time, value }) => time),
        datasets: [
          {
            data: priceData.map(({ time, value }) => parseFloat(value)),
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            borderColor: vars.colors.blue,
          },
        ],
      });
    };
    fetchData();
    // fetch(`${vars.apiHost}/v2/-/ohlc-stats/${identifier}/hodl/${resolution}`)
    //   .then((response) => response.json())
    //   .then((stats) => {
    //     const oldPrice = stats[0][1];
    //     const currentPrice = stats[stats.length - 1][1];

    //     setPrice(currentPrice.split("."));
    //     setChange(((oldPrice - currentPrice) / oldPrice) * -100);
    //     setData({
    //       labels: stats.map(([timestamp, value]) => timestamp),
    //       datasets: [
    //         {
    //           data: stats.map(([timestamp, value]) => parseFloat(value)),
    //           pointBackgroundColor: "rgba(0, 0, 0, 0)",
    //           pointBorderColor: "rgba(0, 0, 0, 0)",
    //           borderColor: vars.colors.blue,
    //         },
    //       ],
    //     });
    //   });
  }, [selected, resolution]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: function (context) {
          let newTooltip;

          if (context.tooltip.opacity) {
            const { caretX, caretY } = context.tooltip;
            const { label, raw } = context.tooltip.dataPoints[0];

            const time = moment(new Date(label)).format('D MMM YYYY, h:mmA');

            newTooltip = {
              value: `$${raw.toFixed(12)}`,
              time,
              pos: { top: `${caretY}px`, left: `${caretX}px` },
            };
          }

          if (newTooltip && !isEqual(tooltip, newTooltip)) {
            setTooltip(newTooltip);
          }
        },
      },
    },
  };

  const getResolutionText = (res) => {
    switch (res) {
      case '0':
        return '1d';
      case '1':
        return '1w';
      case '2':
        return '1m';
      case '3':
        return '1y';
      default:
        return '1d';
    }
  };

  return (
    <Root className="price-chart box">
      <div className="flex">
        <strong className="hodl">$HODL</strong>
        <ExchangePicker
          exchanges={exchanges}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <h2>
        <small>$</small>
        {price[0]}.<small>{price[1]?.substr(0, 12)}</small>
        <div className="change">
          <b className={change < 0 ? 'negative' : 'positive'}>
            {formatNumber(change, 2)}%
          </b>
        </div>
      </h2>
      <div className="chart">
        {Object.keys(data).length ? <Line data={data} options={options} /> : ''}
        {tooltip ? (
          <div className="tooltip" style={tooltip.pos}>
            <label>{tooltip.value}</label>
            <small>{tooltip.time}</small>
            <div />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="flex switch">
        {'0 1 2 3'.split(' ').map((res) => (
          <a
            key={res}
            className={resolution === res ? 'selected' : ''}
            onClick={() => {
              setResolution(res);
              setTooltip();
            }}
          >
            {getResolutionText(res)}
          </a>
        ))}
      </div>{' '}
      <div className="watermark"></div>
    </Root>
  );
};

export default PriceCharts;
