import React, { useState } from 'react';
import { Root } from './style';

import { useSiteData } from 'utils';

import ExchangePicker from '../ExchangePicker';

const TransactionTaxes = ({ selected, setSelected }) => {
  const fallback = {
    buy: {
      percentage: 0,
      spec: [],
    },
    transfer: {
      percentage: 0,
      spec: [],
    },
    sell: {
      percentage: 0,
      spec: [],
    },
  };

  const exchanges = useSiteData().resources.exchanges.reduce(
    (object, exchange) => {
      const buy = (exchange.buyTax || '').split('\n');
      const transfer = (exchange.transferTax || '').split('\n');
      const sell = (exchange.sellTax || '').split('\n');

      object[exchange.identifier] = {
        buy: {
          percentage: buy.shift(),
          spec: buy,
        },
        transfer: {
          percentage: transfer.shift(),
          spec: transfer,
        },
        sell: {
          percentage: sell.shift(),
          spec: sell,
        },
      };

      return object;
    },
    {}
  );

  const keys = Object.keys(exchanges);

  return (
    <Root className="box">
      <div className="flex">
        <strong>Transaction Taxes</strong>
        <ExchangePicker
          exchanges={keys}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className="taxes">
        <div>
          <h4>Buy | Transfer | Sell</h4>
          <h3>{(exchanges[selected] || fallback).buy.percentage}</h3>
          {(exchanges[selected] || fallback).buy.spec.map((s, i) => (
            <span key={`buy${i}`}>{s}</span>
          ))}
        </div>
        {/* <div>
          <h4>Transfer</h4>
          <h3>{(exchanges[selected] || fallback).transfer.percentage}</h3>
          {(exchanges[selected] || fallback).transfer.spec.map((s, i) => (
            <span key={`transfer${i}`}>{s}</span>
          ))}
        </div>
        <div>
          <h4>Sell</h4>
          <h3>{(exchanges[selected] || fallback).sell.percentage}</h3>
          {(exchanges[selected] || fallback).sell.spec.map((s, i) => (
            <span key={`sell${i}`}>{s}</span>
          ))}
        </div> */}
      </div>
    </Root>
  );
};

export default TransactionTaxes;
