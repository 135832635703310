import React, { useEffect, useState } from 'react';
import { Root } from './style';

import { formatNumber } from 'utils';

import vars from 'variables';

const TodaysMarket = () => {
  const [figures, setFigures] = useState({
    bnb: { price: 0, percent_change_24h: 0 },
    btc: { price: 0, percent_change_24h: 0 },
  });

  const [loading, setLoading] = useState(true);

  const fetchDataWithRetry = async (retries = 10, delay = 1000) => {
    try {
      const response = await fetch(`/api/tokeninfo/market-figures`);
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setFigures(data);
      setLoading(false);
    } catch (error) {
      if (retries > 0) {
        console.warn(`Fetch failed, retrying in ${delay / 1000}s...`, error);
        setTimeout(() => fetchDataWithRetry(retries - 1, delay * 2), delay);
      } else {
        console.error('Error fetching data after retries:', error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDataWithRetry();
  }, []);

  return (
    <Root className="box markets">
      <strong>Today's Market</strong>
      {loading ? (
        <label>Loading...</label>
      ) : (
        <>
          <label>Fear and Greed Index</label>
          <span
            className={
              figures.fgi?.value < 20
                ? 'red'
                : figures.fgi?.value < 40
                ? 'orange'
                : figures.fgi?.value < 60
                ? 'yellow'
                : figures.fgi?.value < 80
                ? 'lime'
                : 'green'
            }
          >
            {figures.fgi?.value} - {figures.fgi?.text}
          </span>
          <label>BNB Price</label>
          <span>${formatNumber(figures.bnb?.price)}</span>
          <label>BNB 24H Change</label>
          <span
            className={figures.bnb?.percent_change_24h < 0 ? 'red' : 'green'}
          >
            {formatNumber(figures.bnb?.percent_change_24h, 2)}%
          </span>
          <label>BNB Market Cap</label>
          <span>${formatNumber(figures.bnb?.marketcap)}</span>
          <label>BTC Price</label>
          <span>${formatNumber(figures.btc?.price)}</span>
          <label>BTC 24H Change</label>
          <span
            className={figures.btc?.percent_change_24h < 0 ? 'red' : 'green'}
          >
            {formatNumber(figures.btc?.percent_change_24h, 2)}%
          </span>
        </>
      )}
      {/* <label>Market 24H Volume</label>
      <span>${formatNumber(figures.market_24h_volume, 0)}</span> */}
    </Root>
  );
};

export default TodaysMarket;
