import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  .countdown {
    margin-left: -2px;
    display: flex;
  }

  .countdown div {
    font-size: 1.9rem;
    flex-grow: 1;
    line-height: 1;
    text-align: center;
  }

  .countdown small {
    color: white;
    font-size: 0.6rem;
  }

  .collect {
    color: #50e89d;
    font-size: 1.75rem;
    letter-spacing: 0.5px;
  }

  .collect span {
    margin-bottom: -5px;
    display: block;
  }

  .collect a {
    color: white;
    display: inline-block;
    font-weight: normal;
    font-size: 0.95rem;
    line-height: 1.1;
    text-transform: uppercase;
    border-bottom: 1px solid ${vars.colors.blue};
  }

  .switch {
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    text-align: center;
  }

  .switch span {
    display: inline-block;
    border-radius: 20px;
  }

  .switch > span {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    position: relative;
    background: ${vars.colors.background};
  }

  .switch > span > span {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    position: absolute;
    background: #222;
  }

  .switch > span.selected > span {
    background: ${vars.colors.blue};
  }

  ${vars.desktop.mediaQuery} {
    h4 span.truncated {
      width: calc(50% - 50px);
    }
  }

  ${vars.mobile.mediaQuery} {
    h4 {
      padding-bottom: 6px;
    }
  }
`;

export { Root };
