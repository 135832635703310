import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  h4 {
    padding-top: 2px;
    color: ${vars.colors.blue};
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  h3 {
    padding-bottom: 16px;
    font-size: 1.5rem;
  }

  &.box span {
    margin-bottom: 6px;
    color: #bbb;
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .flex {
    justify-content: space-between;
  }

  ${vars.desktop.mediaQuery} {
    flex: 1;

    .taxes {
      display: flex;
      justify-content: space-between;
    }

    .taxes div {
      width: calc(100% - 8px);
    }
  }

  ${vars.mobile.mediaQuery} {
    &.box span {
      margin-bottom: -3px;
    }

    .flex strong {
      font-size: 0.7rem;
      letter-spacing: 1px;
    }

    h4 {
      margin-top: 25px;
    }

    h3 {
      margin-top: 8px;
      font-size: 1.4rem;
    }

    .taxes div {
      padding-bottom: 8px;
    }

    .taxes span {
      padding-bottom: 8px;
    }
  }
`;

export { Root };
