import React from 'react';
import { Root } from './style';

import { formatNumber, useSiteData } from 'utils';
import { useWeb3 } from 'web3';
import { useState } from 'react';
import { useEffect } from 'react';

const Stats = () => {
  const { bsc } = useWeb3();
  const { index } = useSiteData().pages;

  const [bscState, setBscState] = useState({
    bnbUsdPrice: bsc.bnbUsdPrice,
    hodlUsdPrice: bsc.hodlUsdPrice,
    totalClaimedBNB: bsc.totalClaimedBNB,
    rewardPoolBNB: bsc.rewardPoolBNB,
    hodlers: bsc.hodlers,
    burnWalletHODL: bsc.burnWalletHODL,
    liquidityPoolUSD: bsc.liquidityPoolUSD,
  });

  useEffect(() => {
    setBscState({
      bnbUsdPrice: bsc.bnbUsdPrice,
      hodlUsdPrice: bsc.hodlUsdPrice,
      totalClaimedBNB: bsc.totalClaimedBNB,
      rewardPoolBNB: bsc.rewardPoolBNB,
      hodlers: bsc.hodlers,
      burnWalletHODL: bsc.burnWalletHODL,
      liquidityPoolUSD: bsc.liquidityPoolUSD,
    });
    return () => {
      setBscState({});
    };
  }, [
    bsc.bnbUsdPrice,
    bsc.hodlUsdPrice,
    bsc.totalClaimedBNB,
    bsc.rewardPoolBNB,
    bsc.hodlers,
    bsc.burnWalletHODL,
    bsc.liquidityPoolUSD,
  ]);

  return (
    <Root className="stats">
      <ul className="body-width">
        <li>
          <label>🏆 REWARDS CLAIMED</label>
          <span>
            {formatNumber(bscState.totalClaimedBNB)} BNB (~$
            {formatNumber(
              (bscState.totalClaimedBNB * bscState.bnbUsdPrice) / 1e6,
              1
            )}
            M)
          </span>
        </li>
        <li>
          <label>💰 LIVE REWARD POOL</label>
          <span>
            {formatNumber(bscState.rewardPoolBNB)} BNB (~$
            {formatNumber(bscState.bnbUsdPrice * bscState.rewardPoolBNB, 0)})
          </span>
        </li>
        <li>
          <label>👥 Hodlers</label>
          <span>
            {formatNumber(parseInt(index.hodlers) || bscState.hodlers, 0)}
          </span>
        </li>
        <li>
          <label>📈 Market Cap</label>
          <span>
            $
            {formatNumber(
              Math.round(
                bscState.hodlUsdPrice * (10000000000 - bscState.burnWalletHODL)
              )
            )}
          </span>
        </li>
        <li>
          <label>🏦 Liquidity Pool</label>
          <span>${formatNumber(Math.round(bscState.liquidityPoolUSD, 0))}</span>
        </li>
        <li>
          <label>🔒 Liquidity Lock</label>
          <span>Jan 2280</span>
        </li>
      </ul>
    </Root>
  );
};

export default Stats;
